<template>
    <div>
        <div class="table_box">
            <el-row align="middle" type="flex"
                    style="background: white;padding-left: 0px;padding-top: 10px;padding-bottom: 10px;">
                <el-col :span="8">
                    <div class="keyword">
                        <div class="label">{{$t("m.order.xiadanshijian")}}：</div>
                        <el-date-picker
                                v-model="orderTime"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                align="right"
                                unlink-panels
                                :range-separator="$t('m.pubilc.zhi')"
                                :start-placeholder="$t('m.order.kaishishijian')"
                                :end-placeholder="$t('m.order.jieshushijiang')"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                    </div>

                </el-col>
                <el-col :span="6" style="margin-left: 20px">
                    <div class="keyword">
                        <div class="label">{{$t("m.order.dingdanzhuangtai")}}：</div>
                        <el-select v-model="orderState"
                                   clearable
                                   :placeholder="$t('m.order.qingxuanze')"
                                   style="width: 350px;max-width: 350px">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="keyword">
                        <div class="label">{{$t("m.order.dingdanssousuo")}}：</div>
                        <el-input v-model="orderNum"
                                  :placeholder="$t('m.order.qingshurudingdanbianhao')"
                                  style="max-width: 305px"/>
                    </div>

                </el-col>
                <el-col :span="2" align="end" style="margin-right: 20px" :offset="2">
                    <el-button type="primary" size="small" @click="find">{{$t("m.pubilc.sousuo")}}</el-button>
                </el-col>
            </el-row>
            <div class="order_content">
                <el-table class="cz-table" :data="tableData" tooltip-effect="dark" style="width: 100%">
                    <el-table-column prop="orderNo" :label="$t('m.order.dingdanno')"/>
                    <el-table-column prop="paymentTime" :label="$t('m.order.xaidanshijian')" width="100px" />
                    <el-table-column prop="merchantName" :label="$t('m.order.maijia')">
                        <template slot-scope="scope">
                            <div>{{scope.row.merchantName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="templateName" :label="$t('m.order.template')">
                        <template slot-scope="scope">
                            <div>{{scope.row.templateName}}</div>
                            <div class="tips">{{scope.row.orderDesc}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="miniProName" :label="$t('m.order.miniProName')" width="150px">
                        <template slot-scope="scope">
                            <div>{{scope.row.miniProName}}</div>
                            <div class="tips">{{scope.row.miniProId}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="couponName" :label="$t('m.order.couponName')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.couponName">{{scope.row.couponName}}</div>
                            <div v-else>——</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="channel" :label="$t('m.order.payType')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.channel!=null">
                                <cz-svg-icon class="wechat-logo" name="wachart" v-if="scope.row.channel==0"/>
                                <cz-svg-icon class="wechat-logo" name="zf" v-if="scope.row.channel==1"/>
                                {{channelList[scope.row.channel]}}
                            </div>
                            <div v-else>——</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentAmount" :label="$t('m.order.dingdanjine')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.paymentAmount!=null">
                                {{scope.row.paymentAmount}}
                            </div>
                            <div v-else>——</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.order.dingdanzhuangtai')">
                        <template slot-scope="scope">
                            <div :class="scope.row.status === 1 ? 'success-status' : 'other-status'">{{orderStatus(scope.row.status)}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="invoiceStatus" :label="$t('m.order.fapiaozhuangtai')">
                        <template slot-scope="scope">
                            <div class="primasColor" v-if="scope.row.invoiceStatus==0">{{invoiceStatusList[scope.row.invoiceStatus]}}</div>
<!--                            v-if="scope.row.invoiceStatus==1||scope.row.invoiceStatus==2"-->
                            <el-popover
                                    v-if="scope.row.invoiceStatus==1"
                                    placement="bottom"
                                    width="230"
                                    title="发票信息"
                                    trigger="hover">
                                <div v-if="scope.row.invoiceInfo!=null" class="invoiceClass" @click="handleCopy(scope.row.invoiceInfo)">
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">发票类型：</el-col>
                                        <el-col :span="16">{{invoiceList[scope.row.invoiceInfo.type]}}</el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">发票抬头：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.title}}</el-col>
                                    </el-row>
                                    <el-row v-if="scope.row.invoiceInfo.type === 0">
                                        <el-col :span="8" justify="end" align="middle">公司税号：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.taxNo}}</el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">快递地址：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.address}}</el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">联系人：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.userName}}</el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">联系电话：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.userPhone}}</el-col>
                                    </el-row>
                                </div>
                                <div class="primasColor" slot="reference" style="cursor: pointer">
                                    {{invoiceStatusList[scope.row.invoiceStatus]}}
                                    <i class="el-icon-copy-document"></i>
                                </div>
                            </el-popover>
                            <el-popover
                                    v-if="scope.row.invoiceStatus==2"
                                    placement="bottom"
                                    width="230"
                                    title="发票发送信息"
                                    trigger="hover">
                                <div class="invoiceClass" @click="handleCompany(scope.row.invoiceInfo)">
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">{{$t('m.order.kuaidigongsi')}}：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.expressCompanyName}}</el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="8" justify="end" align="middle">{{$t('m.order.kuaidibianhao')}}：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.expressCode}}</el-col>
                                    </el-row>
                                    <el-row v-if="scope.row.invoiceInfo.type === 0">
                                        <el-col :span="8" justify="end" align="middle">{{$t('m.order.kuaidibeizhu')}}：</el-col>
                                        <el-col :span="16">{{scope.row.invoiceInfo.expressNote}}</el-col>
                                    </el-row>
                                </div>
                                <div class="primasColor" slot="reference" style="cursor: pointer">
                                    {{invoiceStatusList[scope.row.invoiceStatus]}}
                                    <i class="el-icon-copy-document"></i>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.pubilc.caozuo')" align="center">
                        <template slot-scope="scope">
                            <el-button type="text"
                                       size="mini"
                                       v-if="scope.row.invoiceStatus == 1"
                                       @click="deliverGood(scope.row)">
                                {{$t('m.order.fasongfapiao')}}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="table-pager"
                               background
                               layout="total,prev, pager, next"
                               :total="total"
                               @current-change='current_change'>
                </el-pagination>
            </div>
        </div>
        <el-dialog
                :title="$t('m.pubilc.tishi')"
                :visible.sync="trackingNumberVisible"
                width="30%">
            <el-form label-width="80px" :model="trackingNumber" :rules="trackingRules" ref="trackingForm">
                <el-form-item :label="$t('m.order.kuaidigongsi')" prop="trackingCompany">
                    <el-select v-model="trackingNumber.trackingCompany">
                        <el-option
                                v-for="item in trackingCompanys"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('m.order.kuaidibianhao')" prop="number">
                    <el-input v-model="trackingNumber.number" :placeholder="$t('m.order.qingshurukuaididanhao')" maxLength="20"/>
                </el-form-item>
                <el-form-item :label="$t('m.order.kuaidibeizhu')">
                    <el-input v-model="trackingNumber.note" :placeholder="$t('m.order.qingshurukuaididanhao')" maxLength="20"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="trackingNumberVisible = false">{{$t("m.pubilc.cancel")}}</el-button>
                <el-button type="primary" @click="trackingGoods">{{$t("m.order.biaojiweiyifasong")}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import {selectOrderList, commitInvoice,getInvoiceInfoByOrderId} from '../../../request/api/order.js'
    export default {
        data() {
            return {
                customerId: null,
                item: null,
                trackingNumberVisible:false,
                trackingNumber:{},
                trackingCompanys:[
                    this.$t('m.order.yuantong_kuaidi'),
                    this.$t('m.order.zhongtong_kuaidi'),
                    this.$t('m.order.shentong_kuaidi'),
                    this.$t('m.order.yunda_kuaidi'),
                    this.$t('m.order.baishi_kuaidi'),
                    this.$t('m.order.shunfeng_kuaidi'),
                    this.$t('m.order.tiantian_kuaidi'),
                    this.$t('m.order.ems_kuaidi'),
                    this.$t('m.order.youzheng_kuaidi'),
                    this.$t('m.order.debang_kuaidi'),
                    this.$t('m.order.jingdong_kuaidi'),
                    this.$t('m.order.qita_kuaidi'),
                ],
                channelList:[
                    this.$t('m.order.wxPay'),
                    this.$t('m.order.zhifubao'),
                ],
                invoiceStatusList:[
                    this.$t('m.order.weishengqin'),
                    this.$t('m.order.yishenqin'),
                    this.$t('m.order.yifasong'),
                ],
                invoiceList:[
                    this.$t('manager.receipt.company'),
                    this.$t('manager.receipt.person')
                ],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: this.$t('m.order.jintian'),
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime());
                                picker.$emit('pick', [start, end]);
                            }
                        },
                        {
                            text: this.$t("m.order.zuotian"),
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                                end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: this.$t("m.order.zuojinyizhou"),
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit('pick', [start, end]);
                            }
                        },
                        {
                            text: this.$t('m.order.zuijinyigeyue'),
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit('pick', [start, end]);
                            }
                        },
                        {
                            text: this.$t('m.order.zuijinsangeyue'),
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit('pick', [start, end]);
                            }
                        }]
                },

                options: [
                    {
                        value: '0',
                        label: this.$t('m.order.daidukuang')
                    }, {
                        value: '1',
                        label: this.$t('m.order.yifukuan')
                    }],
                orderState: '',
                orderTime: [],
                orderNum: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
                tableData: [],
                trackingRules:{
                    trackingCompany:[
                        { required: true, message: this.$t("m.order.qingxuanzekuaidigongsi"), trigger: 'change' },
                    ],
                    number:[
                        { required: true, message: this.$t("m.order.qingshurukuaididanhao"), trigger: 'blur' },
                    ]
                }
            }
        },
        created() {
            this.customerId = this.$route.query.customerId
            this.queryPage()
        },
        watch:{
            trackingNumberVisible(){
                this.trackingNumber = {}
            }
        },
        methods: {
            trackingGoods(){
                let _this = this;
                this.$refs.trackingForm.validate(res=>{
                    if(res){
                        let data = {
                            invoiceId:_this.item.invoiceInfo.id,
                            expressCompanyName : _this.trackingNumber.trackingCompany,
                            expressCode : _this.trackingNumber.number,
                            expressNote:_this.trackingNumber.note,
                        };
                        commitInvoice(data).then(res=>{
                            _this.queryPage()
                            _this.trackingNumberVisible = false;
                            _this.$message({
                                message: '发送成功',
                                type: 'success'
                            });
                        });
                    }
                })
            },
            deliverGood(item){
                this.item = item;
                this.trackingNumberVisible = true;
            },
            find() {
                this.pageNum = 1
                this.queryPage()
            },
            queryPage() {
                let params = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    keyword: this.orderNum,
                    merchantId:this.customerId
                };
                if(this.orderTime&&this.orderTime.length>0){
                    params.startTime = this.orderTime[0]
                    params.endTime = this.orderTime[1]
                }
                if(parseInt(this.orderState)>=0){
                    params.status = parseInt(this.orderState)
                }
                selectOrderList(params).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        this.total = res.data.total
                    }
                })
            },

            current_change(e) {
                this.pageNum = e
                this.queryPage()
            },
            //订单状态字符串
            orderStatus(state) {

                let str = '';
                switch (state) {
                    case 0:
                        str = this.$t('m.order.chuangjian')
                        break;
                    case 1:
                        str = this.$t('m.order.yizhifu')
                        break;
                    case 2:
                        str = this.$t('m.order.yiquxiao')
                        break;
                }

                return str
            },
            //复制发票内容
            handleCopy(data){
                let typeText = "发票类型："+ this.invoiceList[data.type]
                let titleText = "发票抬头："+ data.title
                let taxNoText = "公司税号："+ data.taxNo
                let addressText = "快递地址："+ data.address
                let userNameText = "联系人："+ data.userName
                let userPhoneText = "联系电话："+ data.userPhone
                let value = null
                if(data.type==1){
                    value = typeText+"\n"+titleText+"\n"+addressText+"\n"+userNameText+"\n"+userPhoneText
                }else{
                    value = typeText+"\n"+titleText+"\n"+taxNoText+"\n"+addressText+"\n"+userNameText+"\n"+userPhoneText
                }
                this.copyMsg(value)
            },
            handleCompany(data){
                let expressCompanyName = this.$t('m.order.kuaidigongsi') + data.expressCompanyName
                let expressCode = this.$t('m.order.kuaidibianhao') + data.expressCode
                let expressNote = this.$t('m.order.kuaidibeizhu') + data.expressNote
                let value = expressCompanyName+"\n"+expressCode+"\n"+expressNote
                this.copyMsg(value)
            },
            copyMsg(value){
                let oInput = document.createElement('textarea');
                oInput.value = value;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message({
                    message: '复制成功',
                    type: 'success'
                });
                oInput.remove()
            },
            //是否售后中
            isRefund(item){
                let refundStatus = item.refundStatus;
                return refundStatus === 1 || refundStatus === 2;
            },
        },
    }
</script>
<style scoped lang="less">
    @import "../../../assets/style/index";
    @import "../../../assets/style/table";

    .order_content {
        width: 98%;
        margin: auto;
        background: white;
    }
    .table_box {
        padding: 15px;
        background: #fff;
        margin: auto;
    }
    .keyword {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        .label{
            width: 70px;
            text-align: right;
            flex-shrink: 0;
        }
    }
    .wechat-logo{
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
    .primasColor{
        color: #ff6f33;
    }
    .tips{
        font-size: 12px;
        color: #999;
        line-height: 15px;
    }
    .success-status {
        color: #52C41A;
        background: #F6FFED;
        font-size: 12px;
        border: 1px solid #B7EB8F;
        border-radius: 2px;
        width: 52px;
        height: 22px;
        line-height: 22px;
        margin: 0px auto;
        text-align: center;
    }

    .other-status {
        color: #000000A6;
        background: #0000000A;
        font-size: 12px;
        border: 1px solid #00000026;
        border-radius: 2px;
        width: 52px;
        height: 22px;
        line-height: 22px;
        margin: 0px auto;
        text-align: center;
    }
    .invoiceClass{
        line-height: 30px;
        /deep/ .el-col-8{
            font-size: 12px;
        }
    }
</style>
