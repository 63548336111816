import {
    request
} from 'request/request.js'
import {POST} from "../request";


// // 分页查询订单
// export function queryPage({
//                               pageNum,
//                               pageSize,
//                               endTime,
//                               keyword,
//                               startTime,
//                               status,
//                           }) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/order/queryPage',
//         data: {
//             pageNum,
//             pageSize,
//             endTime,
//             keyword,
//             startTime,
//             status,
//         }
//     })
// }
//
//
// export function exportExcel({
//                                 id
//                             }) {
//     return request({
//         method: 'GET',
//         url: '/mall/minipro/data/exportExcel?id=' + id,
//         // data:{
//         //     id
//         // },
//         responseType: 'blob'
//     })
// }

// 分页查询订单
export function selectOrderList(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getTemplateOrderInfoList',
        data: data
    })
}

//发送发票
export const commitInvoice = (data)=>{
    return POST('/merchant/admin/commitInvoice',data);
}

//获取企业的订单信息
export const getInvoiceInfoByOrderId = (data)=>{
    return POST('/merchant/admin/getInvoiceInfoByOrderId',data);
}
//
// export function selectOrderInfo(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/order/queryOrderInfo',
//         data: data
//     })
// }
//
//
// export function updateOrderById(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/order/updateOrderById',
//         data: data
//     })
// }
//
// export function backMoney(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/order/backMoney',
//         data: data
//     })
// }
//
// //获取售后列表
// export function getEnhanceOrderRefundVoList(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/orderRefund/getEnhanceOrderRefundVoList',
//         data: data
//     })
// }
//
// //获取最近的售后信息
// export function lastRefundInfo(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/orderRefund/refundInfo',
//         data: data
//     })
// }
//
// //同意退款-退货退款-换货申请
// export function agreeApply(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/orderRefund/agreeApply',
//         data: data
//     })
// }
//
// //拒绝退款-退货退款-换货申请
// export function rejectApply(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/orderRefund/rejectApply',
//         data: data
//     })
// }
//
// //确认线下退款完成-确认线下退款完成
// export function confirmRefund(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/orderRefund/confirmRefund',
//         data: data
//     })
// }
//
//
// //商家填写物流
// export function saveExchangeExpress(data) {
//     return request({
//         method: 'post',
//         url: '/mall/minipro/orderRefund/saveExchangeExpress',
//         data: data
//     })
// }


